var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "vue-simple-suggest",
        {
          attrs: {
            "display-attribute": "text",
            "value-attribute": "id",
            list: _vm.getSuggestionList,
            debounce: 200,
            "max-suggestions": 10,
            "min-length": 2,
            placeholder: _vm.placeholder
          },
          on: { select: _vm.onSelect },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                {
                  staticClass: "misc-item",
                  attrs: { slot: "misc-item-below" },
                  slot: "misc-item-below"
                },
                [_c("span", [_vm._v(_vm._s(_vm.trans("Loading...")))])]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "model", rawName: "v-model", value: _vm.id, expression: "id" }
        ],
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.id = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.items, function(item) {
        return _c(
          "div",
          { key: item.id, staticClass: "align-items-baseline d-flex" },
          [
            _c("input", {
              staticClass: "mr-2",
              attrs: {
                type: "checkbox",
                name: _vm.name + "[]",
                id: "search-filter-" + _vm.name + "-" + item.id
              },
              domProps: { value: item.id, checked: item.selected }
            }),
            _vm._v(" "),
            _c(
              "label",
              { attrs: { for: "search-filter-" + _vm.name + "-" + item.id } },
              [_vm._v("\n            " + _vm._s(item.text) + "\n        ")]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
////////////////////////
// axios configuration//
////////////////////////
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

//////////////////////
// Vue configuration//
//////////////////////
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

// Translation method
// see:
// https://medium.com/@serhii.matrunchyk/using-laravel-localization-with-javascript-and-vuejs-23064d0c210e
// https://medium.com/@konafets/localization-with-laravel-and-vuejs-e27068e68ee8
// https://github.com/martinlindhe/laravel-vue-i18n-generator
// https://github.com/rmariuzzo/laravel-js-localization
// https://laracasts.com/discuss/channels/vue/laravel-translation-in-vue
// https://github.com/rmariuzzo/lang.js
// https://github.com/vemcogroup/laravel-translation

// Vue.prototype.$translations = {};
// Custom function to translate in Vue files
// source: https://pineco.de/using-laravels-localization-js/
window.i18n = window.i18n || [];
Vue.mixin({
    methods: {
        trans: function (key) {
            return i18n[key] || key;
        },
        asset: function (path) {
            let prefix = document.head.querySelector('meta[name="asset-url"]').content
            return prefix + '/' + path.replace(/^\/+/, '')
        }
    }
});

// Bugsnag for logging Vue errors
if (process.env.NODE_ENV == 'production') {
    Bugsnag.start({
        apiKey: '3ccfb40647cdad34a305c0c4718c882b',
        plugins: [new BugsnagPluginVue(Vue)],
        releaseStage: process.env.NODE_ENV,
        appType: 'app_js'
    });
}

// Global components
Vue.component('search-filter', require('./components/GuestSearchFilter.vue').default);

Vue.config.productionTip = false;

new Vue({
    el: '#app'
});
